/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useTheme } from '../../hooks';
import { makeStyles } from '../../material';

const spellingSuggestionBaseStyle = {
  alignItems: 'center',
  display: 'flex',
  fontSize: '14px',
  padding: '0px 24px',
};

export const useStyles = () => {
  const theme = useTheme();

  return makeStyles(
    {
      searchInsteadInnerContainer: {
        ...spellingSuggestionBaseStyle,
        flexDirection: 'row',
        fontSize: '13px',
      },
      spellingSuggestionContainer: {
        marginBottom: '8px',
      },
      spellingSuggestionInnerContainer: {
        ...spellingSuggestionBaseStyle,
        flexDirection: 'row',
        fontSize: '14px',
      },
      spellingSuggestionText: {
        color: theme.designSystem.colors.aux.main,
        display: 'flex-inline',
        fontSize: 'inherit',
        fontWeight: 500,
      },
      spellingSuggestionTextButton: {
        '&:hover': {
          textDecoration: 'underline',
        },
        color: '#2C65BA',
        cursor: 'pointer',
        display: 'flex-inline',
        fontSize: 'inherit',
        fontWeight: 500,
        marginLeft: '4px',
      },
    },
    { index: 1 },
  )();
};
