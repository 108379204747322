import { SearchPeopleResult } from '@outmind/types';
import React from 'react';

import DefaultPersonImg from '../../../assets/default-person.png';
import { useTranslations } from '../../../hooks';
import { Avatar, Card, CardContent, Grid, SearchIcon } from '../../../material';
import { Actions, useDispatch, useSelector } from '../../../store';
import { SanitizedHtmlTypography } from '../..';
import { ContactPersonButtons } from '../../ContactPersonButtons';
import { ActionButton } from '../ActionButton';
import { DocMatchedModal } from './DocMatchedModal';
import { useStyles } from './styles';

export const Person: React.FC<PersonProps> = ({ result }) => {
  const {
    document: { accessLink, avatarUrl, email, fullName, mobilePhone },
    highlights,
  } = result;

  const dispatch = useDispatch();

  const { t } = useTranslations();

  const classes = useStyles();

  const isOpened = useSelector((s) => s.previews.isOpened);

  const displayedEmail = highlights?.email || email;

  const variants: ('creatorOf' | 'workedOn' | 'responsibleOf')[] = [
    'creatorOf',
    'responsibleOf',
    'workedOn',
  ];

  const displayedName = highlights?.fullName ?? fullName ?? displayedEmail;

  const searchByPersonButton = (
    <div className={classes.actionButtonContainer}>
      <ActionButton
        icon={<SearchIcon fontSize="small" />}
        onClick={() => {
          dispatch(Actions.addPersonFilter(result.document));
          dispatch(Actions.setChosenIndexTab('*'));
        }}
        title={t('search_by_person')}
      />
    </div>
  );
  const personName = (
    <div className={classes.nameContainer}>
      <SanitizedHtmlTypography className={classes.name} href={accessLink} html={displayedName} />
      <div className={classes.score}>
        {variants.map((variant) => (
          <DocMatchedModal key={variant} result={result} variant={variant} />
        ))}
      </div>
      {searchByPersonButton}
    </div>
  );

  const personEmail = (
    <SanitizedHtmlTypography className={classes.email} html={displayedEmail} title={email} />
  );

  const personHeader = (
    <>
      {personName}
      {displayedName !== displayedEmail ? personEmail : null}
    </>
  );

  return (
    <Grid
      item
      lg={isOpened ? 6 : 4}
      md={isOpened ? 12 : 6}
      onContextMenu={(e) => e.preventDefault()}
      sm={12}
      xl={isOpened ? 6 : 3}
    >
      <div className={classes.personCardContainer}>
        <Card classes={{ root: classes.cardRoot }} className={classes.personCard} elevation={0}>
          <CardContent className={classes.personCardContent}>
            <div className={classes.personInfo}>
              <Avatar className={classes.personAvatar} src={avatarUrl ?? DefaultPersonImg} />
              <div className={classes.findPeopleResultElementInner}>{personHeader}</div>
            </div>
            <div className={classes.contactPersonButtonsContainer}>
              <ContactPersonButtons
                alwaysEmail
                alwaysPhone
                email={email}
                phoneNumber={mobilePhone}
              />
            </div>
          </CardContent>
        </Card>
      </div>
    </Grid>
  );
};

interface PersonProps {
  result: SearchPeopleResult;
}
