import { TranslationMap } from './types';

export const frTranslation: TranslationMap = {
  '14day_free_trial': "14 jours d'essai offerts",
  accept_terms_helper:
    'J’accepte les {{- termsOfServiceLink}} et la {{- privacyPolicyLink}} d’Outmind.',
  accounts: 'Comptes',
  add: 'Ajouter',
  add_bookmark: 'Mettre en favoris',
  add_label: 'Ajouter label',
  add_source: 'Nouvelle source',
  add_source_classic_helper:
    "Pour connecter une nouvelle source, cliquez sur l'outil correspondant. Vous serez redirigé pour saisir vos identifiants.",
  add_source_dialog_sub_title: "Vous pourrez en ajouter d'autres par la suite.",
  add_source_dialog_title: 'Ajouter une nouvelle source',
  add_source_dialog_title_onboarding: 'Pour démarrer, ajoutez votre première source',
  add_source_login_password_helper:
    'Pour connecter cette nouvelle source, veuillez renseigner vos identifiants ci-dessous.',
  add_source_onboarding_helper:
    "Commencez par <b>connecter votre premiere source</b> en cliquant sur l'un des outils disponibles ci-dessous. Vous serez redirigé pour renseigner vos identifiants. Vous pourrez ajouter d'autres sources plus tard.",
  add_source_security_helper:
    'Lorsque vous connectez un nouvel outil, vos données restent protégées. Toutes vos données sont chiffrées lorsqu’elles transitent ou sont stockées sur Outmind.',
  add_tag: 'Ajouter tag',
  added: 'Ajouté',
  admin: 'Administration',
  admin_consent_successfully_granted:
    'Le consentement administrateur a été correctement donné pour votre organisation.',
  admin_setting: 'Gestion administrateur',
  administrator: 'Administrateur',
  after: 'Après',
  all_my_emails: 'Tous mes emails',
  and_x_other_persons: ' et {{count}} autre personne',
  and_x_other_persons_plural: ' et {{count}} autres personnes',
  anytime: 'Date indifférente',
  app_crash_helper_text: 'Si cela se produit à nouveau, contactez le support.',
  archived_conversations: 'Conversations archivées',
  at_least_n_other_documents: 'Au moins {{count}} autres documents',
  available_short: 'Dispo',
  before: 'Avant',
  box_elements: 'tous les fichiers',
  box_items: 'dossiers selectionnés',
  box_new_items: 'tous les nouveaux dossiers créés',
  by: 'par :',
  cancel: 'Annuler',
  cancel_configuration: 'Annuler la création de cette nouvelle source ?',
  cancel_configuration_helper:
    "Si vous choisissez d'annuler la configuration de cette nouvelle source, elle sera entièrement supprimée.",
  cancel_configuration_helper_plural:
    "Si vous choisissez d'annuler la configuration de ces nouvelles sources, elles seront entièrement supprimées.",
  cancel_configuration_plural: 'Annuler la création de ces nouvelles sources ?',
  caution: 'Attention !',
  characters_exceeded: '{{maxCharacters}} caractères sont dépassés',
  check_if_file_is_stored_somehere_else:
    "Vérifiez que ce document n'est pas stocké sur un support inaccessible depuis ce poste (disque amovible, autre ordinateur, etc).",
  choose_color: 'Choisissez votre couleur',
  choose_synced_items: 'Choisissez les éléments à synchroniser',
  click_here: 'Cliquez ici',
  click_here_to_install: 'Cliquez ici pour installer',
  click_if_no_popup: "Cliquez ici si aucune pop-up ne s'ouvre.",
  click_if_no_redirect: "Cliquez ici si vous n'êtes pas redirigé.",
  click_to_access_source:
    "Source mise à disposition par votre organisation, cliquez pour valider l'accès.",
  click_to_reconnect: 'Cliquez pour se reconnecter',
  coming_soon: 'Bientôt disponible',
  company_code: "Code de l'entreprise",
  complete_your_information: 'Complétez vos informations',
  complete_your_information_helper: "Plus qu'un petit effort avant d'utiliser Outmind",
  configure_source: 'Configurez votre nouvelle source',
  configure_source_plural: 'Configurez vos nouvelles sources',
  confirm: 'Valider',
  connect_later: 'Connecter plus tard',
  connect_sources_invite: 'Votre organisation vous a invité à connecter vos sources',
  connecting_extension: "Connexion avec l'extension",
  connection_in_progress: 'Connexion en cours...',
  connector_creation_failed:
    'Une erreur est survenue lors de la création de votre source. Réessayez dans quelques instants et contactez le support si le problème persiste.',
  connector_name: 'Nom du connecteur',
  contact_person: 'Contacter {{displayName}}',
  contact_support: 'Contacter le support',
  continue_configuration: 'Poursuivre la création',
  continue_with_google: 'Continuer avec Google',
  continue_with_microsoft: 'Continuer avec Microsoft',
  create: 'Créer',
  create_account: 'Créer mon compte',
  create_account_helper:
    'Pour créer votre compte Outmind, commencez par vous connecter avec votre compte professionnel sur Google ou Microsoft.',
  create_new_tag: 'Créer un nouveau tag',
  create_one: 'créez-en un.',
  create_tag: 'Créer un tag',
  create_user: 'Créer un utilisateur',
  created: 'Créé ',
  creating_new_source_in_progress: 'Création de la nouvelle source en cours...',
  credentials_update_failed:
    'La connexion à {{source}} a échoué, vérifiez que la bonne adresse e-mail a été sélectionnée.',
  credentials_update_success: 'Votre source {{source}} est à nouveau synchronisée à Outmind.',
  crm_account_annual_revenue: "Chiffre d'Affaires",
  crm_account_industry: 'Secteur',
  crm_account_owner: 'Propriétaire',
  crm_opportunity_account: 'Compte',
  crm_opportunity_amount: 'Montant',
  crm_opportunity_close_date: 'Date de clôture',
  crm_opportunity_owner: 'Propriétaire',
  crm_opportunity_status: 'Statut',
  cta_google_workspace: 'Ajouter tout Google Workspace',
  cta_microsoft_365: 'Ajouter tout Microsoft 365',
  custom: 'Personnalisé',
  data_loading_helper: 'Vous les retrouverez ici dès que votre première source sera synchronisée.',
  date_newest_oldest: "Date (récent d'abord)",
  date_oldest_newest: "Date (ancien d'abord)",
  decommissioned: 'Mis au rebut',
  delete: 'Supprimer',
  delete_source: 'Supprimer la source',
  delete_source_helper:
    "Pas d'inquiétude, vous pouvez toujours rajouter la source ultérieurement si vous changez d'avis.",
  delete_source_plural: 'Supprimer les sources',
  delete_tag_caution_helper: 'Il sera également supprimé pour tous vos collaborateurs.',
  delete_tag_helper: 'Il sera retiré de tous les documents sur lesquels il a été ajouté.',
  delete_tag_title: 'Etes-vous sûr de supprimer ce tag ?',
  deletion_started:
    'La suppression de cette source a débuté. Nous vous avertirons une fois vos données entièrement supprimées.',
  desktop: 'Bureau',
  did_you_mean: "Essayez avec l'orthographe :",
  document_tags: 'Tags du document',
  download_desktop: "Télécharger l'application",
  download_desktop_helper:
    "Avec l'application Outmind, vous ne serez plus qu'à un Ctrl-Espace de tous vos documents.",
  download_extension: "Télécharger l'extension",
  download_extension_helper:
    "Profitez d'une expérience de recherche optimale sur Outmind : téléchargez l'extension pour votre navigateur.",
  downloads: 'Téléchargements',
  dropbox_elements: 'tous les fichiers',
  dropbox_items: 'dossiers selectionnés',
  dropbox_new_items: 'tous les nouveaux dossiers créés',
  edit_a_tag: 'Modifier un tag',
  edit_tag: 'Modifier le tag',
  edit_user: "Modifier l'utilisateur",
  edited: 'Modifié ',
  email: 'Email',
  end: 'Fin',
  enjoy_free_trial: 'Profiter de mon essai gratuit',
  enjoy_free_trial_helper:
    'Vous serez redirigé(e) sur l’interface de paiement. Vous pourrez vous désabonner à tout moment, et aucune transaction ne sera effectuée avant la fin de votre période d’essai de 14 jours.',
  extension_onboarding_helper:
    "Vous avez fait le bon choix ! En installant l'extension Outmind sur votre navigateur, vous avez en quelques instants accès à la connaissance de tous vos documents, où qu'ils se trouvent.",
  extension_onboarding_title:
    "Vous avez correctement installé l'extension Outmind pour votre navigateur",
  field_address: 'Adresse',
  field_agency: 'Agence',
  field_author: 'Auteur',
  field_boardName: 'Nom du tableau',
  field_commercialName: 'Nom du commercial',
  field_consultantName: 'Nom du consultant',
  field_content: 'Contenu',
  field_creator: 'Créateur',
  field_customerName: 'Nom du client',
  field_internalReferenceNumber: 'Référence interne',
  field_manager: 'Responsable',
  field_modelName: 'Modèle',
  field_parents: 'Arborescence',
  field_person: 'Personne',
  field_receiver: 'Destinataire',
  field_sender: 'Émetteur',
  field_title: 'Titre',
  finished: 'Terminé',
  first_name: 'Prénom',
  folders: 'Dossiers',
  for_more_information: "pour plus d'informations",
  formats: 'Formats',
  go_back_to_outmind: 'Retourner sur Outmind',
  group_messages: 'Messages de groupes',
  hi: 'Bonjour',
  history_label: 'Historique',
  i_have_vip_code: "J'ai un code d'accès VIP",
  if_already_signed_up: 'Si vous avez déjà un compte, ',
  if_no_redirect: "Si vous n'avez pas été redirigé",
  if_not_signed_up: "Si vous n'avez pas encore de compte, ",
  in: 'dans :',
  in_progress: 'En cours',
  in_uf: 'Dans "{{name}}"',
  index_error:
    "Une erreur s'est produite sur la recherche des {{index}}. Certains résultats peuvent manquer.",
  'index_name_*': 'Tous',
  index_name_accounts: 'Comptes',
  index_name_cards: 'Cartes',
  index_name_construction_sites: 'Chantiers',
  index_name_construction_tools: 'Outils',
  index_name_contacts: 'Contacts',
  index_name_files: 'Fichiers',
  index_name_mails: 'Mails',
  index_name_messages: 'Messages',
  index_name_missions: 'Missions',
  index_name_opportunities: 'Opportunités',
  index_name_pages: 'Pages',
  index_name_people: 'Collaborateurs',
  installed: 'Installée',
  job_role: 'Position / Rôle',
  keyboard_shortcut: 'Raccourci clavier',
  keyboard_shortcut_helper:
    'Choisissez le raccourci clavier de votre choix pour accéder à Outmind.',
  last_days: '{{days}} derniers jours',
  last_edit: 'Dernière modification ',
  lead: 'Piste',
  learn_more: 'En savoir plus',
  less: 'Moins',
  localdisk: 'Disque local',
  localdisk_out_of_scope:
    "Ces éléments proviennent d'un disque réseau. Pour synchroniser ce type de documents, Créez une source de type disque réseau",
  localfile_with_outmind_app_only:
    "Impossible d'ouvrir ce document avec votre navigateur internet.",
  login_name: 'Identifiant',
  login_verb: 'Se connecter',
  login_with: 'Se connecter avec',
  lost_auth: 'Connexion à {{source}} perdue',
  lost_auth_helper_text:
    'Vous devez mettre à jour votre connexion à la source {{source}} ({{email}}), pour continuer à synchroniser les nouveaux éléments à Outmind.',
  manage_sources: 'Gérer les sources',
  missions_sold_on_this_subject: 'Missions vendues par {{displayName}} sur ce sujet',
  missions_staffed_on_this_subject: 'Missions sur sujet où {{displayName}} était staffé',
  model: 'Modèle',
  more: 'Plus',
  my_browser_extensions: 'Mes extensions de navigateur',
  my_drive: 'Mon Drive',
  my_favorite_files: 'Mes fichiers favoris',
  name: 'Nom',
  name_connector: 'Nommez votre connecteur',
  need_authorization: "Vous avez besoin d'être authentifié pour accéder à la ressource demandée.",
  nest_tag: 'Imbriquer ce nouveau tag dans un tag existant',
  nest_tag_with_children: 'Vous ne pouvez pas imbriquer un tag avec des enfants',
  networkdisk: 'Disque réseau',
  networkdisk_out_of_scope:
    "Ces éléments proviennent d'un disque local. Pour synchroniser ce type de documents, créez une source de type Disque Local",
  new_data_search_refreshed:
    "Les résultats de votre recherche ont été rafraîchis parce que de nouveaux éléments ont été détectés dans l'une de vos sources.",
  new_tab_extension: 'Extension Nouvel Onglet',
  new_tab_extension_helper: "Remplacer votre nouvel onglet par la page d'accueil Outmind",
  new_tag_created: 'Un nouveau tag a été créé',
  new_tag_input: 'Nommez votre nouveau tag',
  new_tag_input_placeholder: 'Nom du tag',
  new_update_available: 'Une nouvelle mise à jour est disponible',
  next_checkup_date: 'Prochain contrôle',
  no_access: "Vous n'avez pas accès à ce document",
  no_content: 'Aucun contenu',
  no_data_yet: "Vous n'avez encore aucune donnée",
  no_data_yet_helper: 'Synchronisez une première source pour retrouver vos données ici.',
  no_date: 'Aucune date',
  no_email: "Pas d'email",
  no_files_in_favorites: 'Aucun fichier dans vos favoris',
  no_items_found: 'Aucun élément trouvé pour cette requête.',
  no_items_found_helper:
    "Essayez avec d'autres mots-clés ou élargissez les paramètres de recherche.",
  no_notification: 'Aucune notification disponible',
  no_object: 'aucun objet',
  no_phone: 'Pas de numéro',
  no_preview_found: 'Prévisualisation non disponible pour ce document',
  no_preview_found_helper: 'Veuillez consulter le document dans la source correspondante.',
  no_source_connected: 'Aucune source connectée',
  no_source_connected_helper: 'Appuyez ci-dessous pour connecter votre première source.',
  no_suggestions_helper:
    'Aucune suggestion disponible. Lancez la recherche pour obtenir plus de résultats.',
  no_syncable_elements: 'Aucun élément synchronisable',
  no_syncable_folders: 'Aucun dossier selectionné',
  no_tag_found: 'Aucun tag trouvé ou ce tag est déjà associé à ce document',
  no_tags_found: 'Aucun tag trouvé sur ce document',
  no_teams: 'Pas de Teams',
  not_installed: 'Absente',
  notifications: 'Notifications',
  on_x_day: 'le {{date}}',
  one_moment_please: "Un instant s'il vous plaît...",
  one_source_syncing: "L'une de vos sources est en cours de synchronisation.",
  onedrive_elements: 'tous les fichiers',
  onedrive_items: 'dossiers séléctionnés',
  onedrive_new_items: 'tous les nouveaux dossiers créés',
  open: 'Ouvrir',
  open_desktop: "Ouvrir l'application Outmind",
  open_in_outmind_app: 'Ouvrir la recherche dans Outmind',
  open_in_source: 'Ouvrir dans la source',
  open_with_file_explorer: 'Ouvrir dans l’explorateur de fichiers',
  other_tags_associated: 'Autres tags associés à ce document',
  out_of_order_short: 'HS',
  outlook_elements: 'tous les emails',
  outlook_items: 'boites mails selectionnées',
  outlook_new_items: 'toutes les nouvelles boites mails créées',
  outmind_dekstop: 'Application Desktop Outmind',
  outmind_extension: 'Extension Outmind',
  outmind_newtab_extension: 'Extension Nouvel Onglet Outmind',
  page_not_found_helper_text: "Désolé, la page que vous cherchiez n'a pas pu être trouvée.",
  password: 'Mot de passe',
  person_creator_of: '{{displayName}} a créé au moins {{count}} document sur ce sujet',
  person_creator_of_plural: '{{displayName}} a créé au moins {{count}} documents sur ce sujet',
  person_responsible_of: '{{displayName}} a vendu au moins {{count}} mission sur ce sujet',
  person_responsible_of_plural: '{{displayName}} a vendu au moins {{count}} missions sur ce sujet',
  person_worked_on: '{{displayName}} a été staffé dans au moins {{count}} mission sur ce sujet',
  person_worked_on_plural:
    '{{displayName}} a été staffé dans au moins {{count}} missions sur ce sujet',
  persons_documents_on_this_subject: 'Documents de {{displayName}} sur ce sujet',
  please_remove_out_of_scope_folders_localdisk:
    'Veuillez retirer les éléments hors de votre ordinateur.',
  please_remove_out_of_scope_folders_networkdisk:
    'Veuillez retirer les éléments hors de votre serveur de fichiers.',
  position_at_account: '{{position}} chez {{accountName}}',
  prefer_web_app: 'Je préfère continuer sur le web',
  preview: 'Aperçu',
  privacy_helper_text:
    "Outmind n'accède à vos données qu'en lecture. Elles sont chiffrées lorsqu'elles transitent vers nos services et lorsqu'elles sont stockées.",
  privacy_policy: 'politique de confidentialité',
  privacy_policy_uf: 'Politique de confidentialité',
  privacy_reminder_content_1:
    'ils ne sont pas stockés, seulement indexés. Cet index est intégralement chiffré.',
  privacy_reminder_content_2: 'ni les équipes Outmind, ni vos collaborateurs.',
  privacy_reminder_content_3: 'vous pouvez les supprimer à tout moment.',
  privacy_reminder_title: 'Respect de la confidentialité',
  privacy_reminder_title_1: 'Outmind n’a pas accès à vos documents',
  privacy_reminder_title_2:
    'Personne d’autre que vous ne peut lire vos mails/messages ou documents non partagés',
  privacy_reminder_title_3: 'Vos données restent à vous',
  privacy_scope_helper_text:
    'Outmind ne demande jamais plus de permissions à vos outils que ce dont il a besoin pour fonctionner correctement.',
  private_channels: 'Canaux privés',
  private_messages: 'Messages privés',
  protected_data: 'Vos données sont protégées',
  public_channels: 'Canaux publics',
  purchase_outmind_single: 'Je veux souscrire à un abonnement personnel',
  purchase_outmind_team: 'Je veux ouvrir des accès pour mon équipe',
  real_time_syncing: 'Synchronisation en temps réel',
  really_delete_source:
    'Souhaitez-vous vraiment supprimer la source {{sourceTypeName}} "{{sourceName}}" ?',
  reconnect: 'Se reconnecter',
  redirection_helper: 'Vous serez redirigé dans quelques instants...',
  refresh: 'Actualiser',
  register_view_title: 'Bienvenue sur Outmind 👋',
  register_view_title_subtitle: 'Pour démarrer, choisissez l’option qui vous correspond.',
  related_to: 'Lié à ',
  relevance: 'Pertinence',
  reload: 'Recharger',
  reload_the_page: 'Recharger la page',
  remove_bookmark: 'Retirer des favoris',
  rename: 'Renommer',
  reset: 'Réinitialiser',
  restart: 'Redémarrer',
  results_count: '({{total}} résultat)',
  results_count_plural: '({{total}} résultats)',
  return: 'Rendu',
  root_folder: 'Tous les fichiers',
  save: 'Sauvegarder',
  search: 'Rechercher',
  search_by_person: 'Rechercher toutes les informations relatives à cette personne',
  search_failed: "Une erreur s'est produite, veuillez réessayer votre recherche.",
  search_in: 'Rechercher dans',
  search_in_folder: 'Chercher dans ce dossier',
  search_instead_for: 'Rechercher avec',
  search_only_in: 'Rechercher uniquement dans',
  search_option: 'Rechercher une option',
  secured: 'Sécurisé',
  see_all_results: 'Voir tous',
  see_all_shared_items: 'Voir tous les éléments partagés',
  see_all_similar_results: 'Voir tous les documents similaires à “{{documentName}}”',
  see_faq: 'Consulter la FAQ',
  see_more: 'Voir plus',
  see_our: 'Consultez notre',
  see_pricing: 'Consulter les tarifs',
  select_folders: 'Ajouter des dossiers',
  select_or_create_tag: 'Sélectionnez un tag ou créez-en un:',
  sent: 'Envoyé ',
  serial_number: 'N° de série',
  share: 'Partager',
  share_my_source:
    'Partager cette source à tous mes collaborateurs sur Outmind (lecture uniquement)',
  share_source_helper:
    'Partager cette source à tous vos collaborateurs sur Outmind (lecture uniquement) ?',
  share_this_source: 'Partager cette source',
  shared_by: 'Partagé par :',
  shared_files: 'Fichiers partagés',
  shared_to_your_team: 'Partagé avec votre équipe',
  sharepoint_elements: 'tous les dossiers et fichiers',
  sharepoint_items: 'drives selectionnés',
  sharepoint_new_items: 'tous les nouveaux drives créés',
  show_in_finder: 'Afficher dans le Finder',
  show_preview: 'Afficher la prévisualisation du document',
  show_tags: 'Voir les tags',
  showing_results_for: 'Résultats pour',
  signin_helper: 'Connectez-vous pour continuer',
  signout: 'Se déconnecter',
  similar_results: 'Résultats similaires',
  slack_elements: 'tous les messages',
  slack_items: 'conversations selectionnées',
  slack_new_items: 'toutes les nouvelles conversations créées',
  something_went_wrong: 'Une erreur inconnue est survenue.',
  sort_by: 'Trier par',
  source_deletion_success: 'Votre source {{- sourceName }} a été entièrement supprimée.',
  source_disabled:
    "Pour ajouter ce type de source, vous devez activer l'option en contactant le support Outmind.",
  source_management: 'Gestion des sources',
  source_management_helper: 'Retrouvez ici les sources que vous avez déjà synchronisées.',
  source_not_available_in_web:
    'Impossible d’ajouter cette source de documents depuis votre navigateur. Pour le faire, utilisez l’application Outmind.',
  source_shared_success: 'Votre source a été partagée avec votre organisation',
  sources: 'Sources',
  sources_access:
    'L’acces aux sources suivantes vous a été mis a disposition par votre administrateur.',
  spotlight_instruction:
    'Vous pouvez également appuyer sur {{- commandHtml}} pour ouvrir Outmind partout sur le web.',
  spotlight_instruction_onboarding: 'Appuyez sur {{- commandHtml }} pour ouvrir Outmind.',
  start: 'Début',
  start_sync_failed:
    'Une erreur est survenue lors du lancement de la synchronisation. Réessayez dans quelques instants ou contactez le support si le problème persiste.',
  start_synchronization: 'Démarrer la synchronisation',
  suggestions_label: 'Suggestions',
  sync_discovered_items: 'Synchroniser tous les futurs éléments (recommandé)',
  sync_discovered_popper:
    'Par défaut, Outmind synchronise {{elements}} des {{items}} ci-dessus. En autorisant la synchronisation des futurs éléments, Outmind synchronisera automatiquement {{newItems}} dans {{source}}.',
  synced_fu: 'Synchronisé',
  synced_l: 'synchronisé',
  synchronization_in_progress: 'Synchronisation en cours...',
  syncing: 'Synchronisation',
  tag_cycle_validator: "Impossible d'associer le même tag comme parent",
  tag_deleted: 'Votre tag a été supprimé',
  tag_first_parent_validator: 'Veuillez sélectionner un parent de premier niveau',
  tag_name_validator: 'Veuillez préciser le nom du tag',
  tag_parent_validator: 'Veuillez sélectionner le parent du tag',
  tag_same_name_error: 'Un tag du même nom existe déjà',
  tag_updated: 'Votre tag a été modifié',
  talk_to_us: 'Nous contacter',
  team_tags: "Tags d'équipe",
  teams_elements: 'toutes les conversations',
  teams_items: 'équipes selectionnées',
  teams_new_items: 'toutes les nouvelles équipes créées',
  terms_and_conditions: 'Conditions générales',
  terms_of_service: "conditions générales d'utilisation",
  tip: 'Astuce',
  tip_enter: 'Entrer pour valider',
  tip_navigate: 'Naviguer',
  trello_elements: 'toutes les cartes',
  trello_items: 'tableaux sélectionnés',
  trello_new_items: 'tous les nouveaux tableaux créés',
  try_it_now: 'Essayez dès maintenant !',
  type_folder: 'Dossiers',
  type_folder_extended: 'Dossiers',
  type_mail_archive: 'Archives',
  type_mail_archive_extended: 'PST, MSG',
  type_media: 'Médias',
  type_media_extended: 'Médias',
  type_pdf: 'PDF',
  type_pdf_extended: 'PDF',
  type_presentation: 'Présentations',
  type_presentation_extended: 'Powerpoint, Google Slides, ...',
  type_sheets: 'Classeurs',
  type_sheets_extended: 'Excel, Google Sheets, ...',
  type_text: 'Textes ',
  type_text_extended: 'Word, Google Docs, ...',
  type_unknown: 'Autres',
  type_unknown_extended: 'Autres',
  types: 'Types',
  unable_to_access_document: "Impossible d'accéder à ce document.",
  unauthorized_access: "Vous n'avez pas accès à la ressource demandée",
  unavailable_short: 'Non dispo',
  update_latest_version: 'Installer la derniere mise à jour.',
  use_outmind_app_for_localfiles:
    "Pour accéder aux documents stockés dans votre ordinateur, utilisez l'application Outmind.",
  users: 'Utilisateurs',
  vip_code: 'VIP Code',
  we_are_creating_your_new_source: 'Nous créons votre nouvelle source...',
  we_are_trying_to_log_you_in: 'Nous essayons de vous connecter.',
  we_are_trying_to_log_you_in_please_wait: 'Nous essayons de vous connecter. Veuillez patienter...',
  we_are_updating_your_o365_connectors: 'Nous mettons à jour vos connecteurs Office 365...',
  welcome_newtab_helper: "Que recherchez-vous aujourd'hui ?",
  welcome_on_outmind: 'Bienvenue sur Outmind',
  welcome_to: 'Bienvenue sur',
  x_days: '{{count}} jour',
  x_days_ago: 'il y a {{count}} jour',
  x_days_ago_plural: 'il y a {{count}} jours',
  x_days_plural: '{{count}} jours',
  x_hours: '{{count}} heure',
  x_hours_ago: 'il y a {{count}} heure',
  x_hours_ago_plural: 'il y a {{count}} heures',
  x_hours_plural: '{{count}} heures',
  x_minutes: '{{count}} minute',
  x_minutes_ago: 'il y a {{count}} minute',
  x_minutes_ago_plural: 'il y a {{count}} minutes',
  x_minutes_plural: '{{count}} minutes',
  x_seconds: '{{count}} seconde',
  x_seconds_ago: 'il y a {{count}} seconde',
  x_seconds_ago_plural: 'il y a {{count}} secondes',
  x_seconds_plural: '{{count}} secondes',
  you_can_close_this_window: 'Vous pouvez fermer cette fenêtre.',
  you_have_been_successfully_logged_in: 'Vous avez été correctement connecté.',
  you_will_be_redirected: 'Vous serez bientôt redirigé',
  your_data_is_coming: "Vos données sont en train d'arriver !",
  your_new_source_is_now_created: 'Votre source a été créée.',
  your_o365_connectors_have_been_updated: 'Vos connecteurs Office 365 ont été mis à jour',
};
