import { makeStyles } from '../../../material';

export const useStyles = makeStyles(
  {
    actionButtonContainer: {
      '& > button': {
        marginLeft: '4px !important',
      },
    },
    cardRoot: {
      borderRadius: '10px',
      boxShadow: '0px 4px 35px rgba(0, 0, 0, 0.08)',
    },
    contactEmailButtonIcon: {
      fontSize: '1.125rem',
      marginLeft: '10px',
    },
    contactPersonButton: {
      width: '100%',
    },
    contactPersonButtons: {
      width: '100%',
    },
    contactPersonButtonsContainer: {
      marginTop: '20px',
      padding: '0px 20px 20px 20px',
    },
    contactPersonButtonsContainer2: {
      flexGrow: 1,
      marginLeft: '10px',
    },
    contactPersonHelperContainer: {
      alignItems: 'center',
      color: '#555',
      display: 'flex',
      padding: '20px 30px',
    },
    contactPersonHelperText: {
      flexGrow: 1,
      fontSize: '1.125rem',
    },
    contactTeamsButtonIcon: {
      marginLeft: '10px',
      maxHeight: '100%',
      width: '18px',
    },
    docMatchCount: {
      '&:hover': {
        backgroundColor: '#f0f0f0',
      },
      alignItems: 'center',
      border: '1px solid #efefef',
      borderRadius: '15px',
      cursor: 'pointer',
      display: 'flex',
      height: 25,
      padding: '3px 5px 3px 5px',
    },
    docMatchCountIcon: {
      color: '#666',
      fontSize: '1rem',
    },
    docMatchCountText: {
      color: '#2b90e9',
      fontWeight: 'bold',
      padding: '3px',
    },
    docMatchCountTooltip: {
      marginRight: '5px',
    },
    docMatchedModalDivider: {
      marginTop: '20px',
      width: '100%',
    },
    documentsMatchedContainer: {
      border: '1px solid #efefef',
      borderRadius: '7px',
      margin: '0 30px',
      width: '600px',
    },
    documentsMatchedDialog: {
      maxWidth: '100vw',
    },
    documentsMatchedDialogContent: {
      padding: '0',
    },
    documentsNotDisplayedHelper: {
      alignItems: 'center',
      backgroundColor: '#f4f4f4',
      color: '#999',
      display: 'flex',
      padding: '15px 30px',
    },
    documentsNotDisplayedHelperText: {
      flexGrow: 1,
    },
    email: {
      color: '#666',
      display: 'block',
      fontSize: '0.75rem',
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    findPeopleResultElementInner: {
      maxWidth: '100%',
      textAlign: 'center',
    },
    messageMatched: {
      alignItems: 'center',
      border: '1px solid black',
      borderRadius: 5,
      cursor: 'default',
      display: 'flex',
      marginLeft: 10,
      padding: 3,
    },
    messageMatchedIcon: {
      height: 'auto',
      marginLeft: 3,
      width: 18,
    },
    name: {
      '&:not([a:link])': {
        color: '#333',
      },
      display: 'block',
      fontSize: '0.875rem',
      fontWeight: 600,
      marginRight: '5px',
      overflowX: 'hidden',
      overflowY: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    nameContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '10px',
    },
    personAvatar: {
      '& > img': {
        borderRadius: '100%',
      },
      height: 70,
      padding: '4px',
      width: 70,
    },
    personCard: {
      color: '#586774',
      fontSize: '0.875rem',
      fontWeight: 'bold',
    },
    personCardContainer: {
      padding: '15px',
    },
    personCardContent: {
      '&:last-child': {
        paddingBottom: '0px',
      },
      color: '#222222',
      display: 'flex',
      flexDirection: 'column',
      padding: '0px',
      textDecoration: 'none',
    },
    personInfo: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      fontSize: '0.875rem',
      height: '135px',
      padding: '10px 20px 10px 20px',
    },
    position: {
      color: '#444444',
      fontSize: '0.875rem',
    },
    role: {
      color: '#586774',
      fontWeight: 400,
    },
    score: {
      display: 'flex',
      flexDirection: 'row',
      fontSize: '0.875rem',
    },
    skillsContainer: {
      display: 'block',
      marginTop: '20px',
      paddingLeft: '10px',
    },
    skillsDisplay: {
      display: 'flex',
      flexBasis: 'auto',
      flexDirection: 'row',
      flexWrap: 'wrap',
      minHeight: '60px',
      paddingTop: '10px',
    },
    skillsText: {
      color: '#586774',
      fontSize: '0.875rem',
      padding: 7,
    },
    skillsTextContainer: {
      backgroundColor: '#f5f6ff',
      border: '1px solid #efefef',
      borderRadius: '15px',
      position: 'relative',
    },
    skillsTextHighLight: {
      color: '#2b90e9',
      fontSize: '0.875rem',
      padding: 7,
    },
    skillsTitleContainer: {
      paddingLeft: '5px',
    },
  },
  { index: 1 },
);
